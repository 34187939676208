// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useCallback } from 'react';
import LogRocket from 'logrocket';
import {
  CHECK_PASSPHRASE_LOG_ROCKET,
  LogRocketKey,
  LOG_ROCKET_COOKIE_NAME,
  LOG_ROCKET_COOKIE_VALUE,
  LOG_ROCKET_LAUNCH_ID
} from '../constants/configuration';
import { setCookie, getCookie } from '../helpers/utils';

export const useLogRocket = () => {
  const [isShowRocketLogMode, setIsShowRocketLogMode] = useState(false);
  const [passphrase, setPassphrase] = useState('');
  const [userInfo, setUserInfo] = useState({ name: '', email: '', id: '' });
  const [count, setCount] = useState(0);

  const handleLaunchLogRocket = useCallback(
    ({ target }) => {
      const num = count + 1;

      if (location.pathname !== '/profile') {
        return;
      }
      if (target.id !== LOG_ROCKET_LAUNCH_ID) {
        setCount(0);
        return;
      }
      if (num === 5 && !isShowRocketLogMode && LogRocketKey) {
        LogRocket.init(LogRocketKey);
        LogRocket.identify('WEB', {
          name: userInfo.name,
          email: userInfo.email,
          id: userInfo.id
        });
        setCount(0);
        setIsShowRocketLogMode(true);
        setCookie(LOG_ROCKET_COOKIE_NAME, LOG_ROCKET_COOKIE_VALUE, 1800);
        return;
      }
      setCount(num);
    },
    [isShowRocketLogMode, count, userInfo]
  );

  const handleStartLogRocket = useCallback(
    ({ key }) => {
      const str = passphrase + key;

      if (location.pathname !== '/profile') {
        return;
      }
      if (!CHECK_PASSPHRASE_LOG_ROCKET.includes(str)) {
        setPassphrase('');
        return;
      }
      if (CHECK_PASSPHRASE_LOG_ROCKET === str && !isShowRocketLogMode && LogRocketKey) {
        LogRocket.init(LogRocketKey);
        LogRocket.identify('WEB', {
          name: userInfo.name,
          email: userInfo.email,
          id: userInfo.id
        });
        setPassphrase('');
        setIsShowRocketLogMode(true);
        setCookie(LOG_ROCKET_COOKIE_NAME, LOG_ROCKET_COOKIE_VALUE, 1800);
        return;
      }
      setPassphrase(str);
    },
    [isShowRocketLogMode, passphrase, userInfo]
  );

  useEffect(() => {
    const isLogRocketInited = !!getCookie(LOG_ROCKET_COOKIE_NAME);
    if (isLogRocketInited && userInfo.id && LogRocketKey) {
      LogRocket.init(LogRocketKey);
      LogRocket.identify('WEB', {
        name: userInfo.name,
        email: userInfo.email,
        id: userInfo.id
      });
      setIsShowRocketLogMode(true);
    }
  }, [userInfo]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getCookie(LOG_ROCKET_COOKIE_NAME)) {
        return;
      }
      clearInterval(intervalId);
      setIsShowRocketLogMode(false);
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [isShowRocketLogMode]);

  useEffect(() => {
    document.addEventListener('click', handleLaunchLogRocket);
    return () => {
      document.removeEventListener('click', handleLaunchLogRocket);
    };
  }, [handleLaunchLogRocket]);

  useEffect(() => {
    document.addEventListener('keypress', handleStartLogRocket);
    return () => {
      document.removeEventListener('keypress', handleStartLogRocket);
    };
  }, [handleStartLogRocket]);

  return { isShowRocketLogMode, setUserInfo };
};
